import { ActionHandler, IOpenedActionBase } from './ActionHandler';
import { IEngine } from '../IEngine';
import { FinalIssuedAction, IssuedInjectScriptAction } from '../../api/client/clientApi';
import { whenBodyReady, getPureUrl, lastN } from '../../lib/helpers';
import { ILogger } from '../../lib/logger';
import { CollectionActionGroupsProvider } from './CollectionActionGroupsProvider/CollectionActionGroupsProvider';
import { HandlingState } from '../../SmartSignalsProfileDebugger/resources/models/HandlingState';

type ElementType = 'Core' | 'Dynamic' | 'Css';

export class InjectScriptActionHandler extends ActionHandler {
    private lastTwenty = lastN<FinalIssuedAction, string>(20, [], x => x.actionId!);

    constructor(engine: IEngine, private logger: ILogger, private _collectionActionGroupsProvider: CollectionActionGroupsProvider,
        private ignoreControlGroupValue: boolean = false) {
        super(engine);
    }

    protected handleAction(issuedObject: SmartSignals.Models.Issued) {
        if (issuedObject.isInControlGroup && !this.ignoreControlGroupValue) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            return;
        }

        const action = issuedObject as FinalIssuedAction;
        const conf = action.conf as IssuedInjectScriptAction;

        if (action.actionId != undefined && conf.collectionActionGroups && conf.collectionActionGroups.length && this._collectionActionGroupsProvider.isCollectionActionGroupsActive(conf.collectionActionGroups)) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            this.engine.unissuedAction(action);
            return;
        }

        if (!conf.allowAsynchronousProcessing && action.triggerUrl && getPureUrl(action.triggerUrl) !== getPureUrl(window.location.href)) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            this.engine.unissuedAction(action);
            return;
        }
        this.lastTwenty.add(action);
        this.inject(conf, issuedObject.id);
    }

    public onActionClosed() {
        //CollectionActionGroupsProvider.clearCollectionActionGroups(conf.collectionActionGroups);
    }

    private inject(conf: IssuedInjectScriptAction, id?: string) {
        if (!conf) {
            super.handligStateChange(id, HandlingState.Rejected);
            return;
        }

        super.handligStateChange(id, HandlingState.Executed);

        this._collectionActionGroupsProvider.activateCollectionActionGroups(conf.collectionActionGroups, conf.actionId);

        const injectors = new Array<Function>(
            () => this.removeOldElements(conf.actionId!)
        );

        if (conf.css)
            injectors.push(() => this.injectCss(conf.css!, this.createElementId(conf.actionId!, 'Css')));

        if (conf.coreScript)
            injectors.push(() => this.injectScript(conf.coreScript!, conf.isCoreModule, this.createElementId(conf.actionId!, 'Core')));

        if (conf.script)
            injectors.push(() => this.injectScript(conf.script!, conf.isModule, this.createElementId(conf.actionId!, 'Dynamic')));

        whenBodyReady(() => {
            injectors.forEach(exec => exec());
        });
    }

    private injectScript(script: string, isModule: boolean, elementId?: string) {
        const e = document.createElement('script');
        if (isModule)
            e.type = "module";
        e.text = script;
        if (elementId)
            e.id = elementId;
        document.body.appendChild(e);
    }

    private injectCss(css: string, elementId?: string) {
        const style = document.createElement('style');
        style.type = 'text/css';
        if (elementId)
            style.id = elementId;
        style.appendChild(
            document.createTextNode(css));

        document.head.appendChild(style);
    }

    private createElementId(id: string, type: ElementType): string | undefined {
        if (!id)
            return undefined;
        return 'config.' + id + '_script' + type
    }

    private removeOldElements(id: string): void {
        if (!id)
            return;
        for (const type of new Array<ElementType>('Core', 'Dynamic', 'Css')) {
            const eid = this.createElementId(id, type);
            if (eid)
                document.getElementById(eid)?.remove();
        }
    }

    public getTriggeredAction(actionId: string) {
        const action = this.lastTwenty.get(actionId);
        if (action) {
            return {
                triggerUrl: action.triggerUrl,
                testMode: action.testMode
            }
        }
        return undefined;
    }

    public registerDisplayPrevention(actionId: string, isModal: boolean, domElement: HTMLElement | undefined, showCallback: (() => void) | undefined, hideCallback: (() => void) | undefined, scope: any): (() => void) | undefined {
        const action = this.lastTwenty.get(actionId);

        if (!isModal && !domElement) {
            console.warn("SMASI registerNonModalDisplayPrevention: missing 'domElement' value");
            return undefined;
        }

        if (!action)
            return undefined;

        scope = scope ?? domElement;
        showCallback = showCallback?.bind(scope);
        hideCallback = hideCallback?.bind(scope);

        if (!action.pageElementDetection?.querySelectors?.length) {
            showCallback?.();
            return () => { };
        }

        let disposed = false;

        let stopElmDetection = () => {
            if (disposed)
                return;

            clearTimeout(openedAction.timeoutId);
            window.removeEventListener('load', checkVisibility, false);
            window.removeEventListener('scroll', checkVisibility, false);
            window.removeEventListener('resize', checkVisibility, false);
            if (openedAction.resizeObserver) {
                openedAction.resizeObserver.disconnect();
                openedAction.resizeObserver = undefined;
            }
            if (openedAction.removeObserver) {
                openedAction.removeObserver.disconnect();
                openedAction.removeObserver = undefined;
            }
            disposed = true;
        };

        const openedAction: IOpenedActionBase = {
            action: action,
            actionOpenedOnOrigin: location.origin,
            actionOpenedOnPath: location.pathname,
            timeoutId: 0,
            stopElementDetection: stopElmDetection
        }

        const checkVisibility = () => this.startThrottling(
            openedAction,
            isModal,
            domElement?.getBoundingClientRect(),
            showCallback,
            hideCallback
        );

        window.addEventListener('load', checkVisibility, false);
        window.addEventListener('scroll', checkVisibility, false);
        window.addEventListener('resize', checkVisibility, false);

        if (domElement) {
            if (typeof ResizeObserver !== 'undefined') {
                let resizeObserver = new ResizeObserver(() => checkVisibility());
                resizeObserver.observe(domElement);
                openedAction.resizeObserver = resizeObserver;
            }

            if (domElement.parentNode && typeof MutationObserver !== 'undefined') {
                var removeObserver = new MutationObserver((mrs) => {
                    for (let mr of mrs) {
                        for (let i = 0; i < mr.removedNodes.length; i++) {
                            var rn = mr.removedNodes[i];
                            if (rn === domElement) {
                                stopElmDetection();
                            }
                        }
                    }
                });

                removeObserver.observe(domElement.parentNode, { childList: true, subtree: false });
                openedAction.removeObserver = removeObserver;
            }
        }

        checkVisibility();

        return stopElmDetection;
    }
}
