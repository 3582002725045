export function tryDelete(obj: any, name: string | number): void {
    try {
        if (obj && name in obj)
            delete obj[name];
    }
    catch (e) {
        obj[name] = undefined;
    }
}

export function createGuid(): string {
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    return guid;
}

export function createTempUserId(): string {
    return '*tmp*' + createGuid().replace(/-/g, '');
}

export function fixUrl(url: string): string {
    if (url) {
        if (url.charAt(url.length - 1) !== '/')
            url += '/';
    }
    return url;
}

export function isSafari(): boolean {
    return navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
}

let domains2: Array<string>;

export function getSubDomains(domain?: string): Array<string> {
    //fiddle.jshell.net =>
    //              net  [0]
    //       jshell.net  [1]
    //fiddle.jshell.net  [2]

    if (!domain) {
        if (!domains2)
            domains2 = getSubDomains(window.location.hostname);
        return domains2;
    }

    const domains = new Array<string>();
    let i = domain.length;

    while ((i = domain.lastIndexOf('.', i - 1)) > 0) {
        domains.push(domain.substr(i + 1));
    }
    domains.push(domain);
    return domains;
}

export function getDomainLevel(domain?: string): number {
    //              net => 0
    //             .net => 0
    //       jshell.net => 1
    //      .jshell.net => 1
    //fiddle.jshell.net => 2

    if (!domain)
        domain = window.location.hostname;

    let i = domain.length,
        lvl = 0;

    while ((i = domain.lastIndexOf('.', i - 1)) > 0) {
        lvl++;
    }
    return lvl;
}

export function getDomainByLevel(level: number, domain?: string): string | undefined {
    //fiddle.jshell.net =>
    //0 =>               net
    //1 =>        jshell.net
    //2 => fiddle.jshell.net

    //.jshell.net =>
    //0 =>               net
    //1 =>        jshell.net

    if (level < 0)
        return;

    if (!domain)
        domain = window.location.hostname;

    let i = domain.length,
        lvl = 0;

    while ((i = domain.lastIndexOf('.', i - 1)) > -1) {
        if (lvl >= level || i === 0)
            return domain.substr(i + 1);
        lvl++;
    }
    return domain;
}

export function wait(ms: number) {
    const start = +(new Date());
    while (<any>new Date() - start < ms);
}

export function insertHTMLAndExecute(target: HTMLElement, htmlToInsert: string) {
    target.innerHTML = htmlToInsert;
    var scripts = target.getElementsByTagName("script");

    for (var i = 0; i < scripts.length; i++) {
        var script = scripts[i];
        var newScript = document.createElement("script");

        if (script.src != "")
            newScript.src = scripts[i].src;
        else
            newScript.text = script.text;

        newScript.type = "text/javascript";
        if (script.parentNode) {
            script.parentNode.insertBefore(newScript, script);
            script.parentNode.removeChild(script);
        }
    }
}

export function insertScriptAndExecute(target: HTMLElement, scriptToInsert: string) {
    target.innerText = scriptToInsert;
    var scripts = target.getElementsByTagName("script");

    for (var i = 0; i < scripts.length; i++) {
        var script = scripts[i];
        var newScript = document.createElement("script");

        if (script.src != "")
            newScript.src = scripts[i].src;
        else
            newScript.text = script.text;

        newScript.type = "text/javascript";
        if (script.parentNode) {
            script.parentNode.insertBefore(newScript, script);
            script.parentNode.removeChild(script);
        }
    }
}

export function whenBodyReady(callback: () => void) {
    if (!callback)
        return;
    if (document.body) {
        callback();
    }
    else {
        let executed = false;
        let execute = () => {
            if (executed)
                return;

            if (document.body) {
                executed = true;
                callback();
            }
        };

        let hReadystatechange = () => {
            execute();
            unregister();
        };
        let hDOMContentLoaded = () => {
            execute();
            unregister();
        };
        let hLoad = () => {
            execute();
            unregister();
        };

        let unregistered = false;
        let unregister = () => {
            if (unregistered)
                return;
            unregistered = true;

            if (window.removeEventListener) {
                window.removeEventListener("readystatechange", hReadystatechange, false);
                window.removeEventListener("DOMContentLoaded", hDOMContentLoaded, false);
                window.removeEventListener("load", hLoad, false);
            }
            else if ((<any>window).attachEvent) {
                (<any>window).detachEvent("onload", hLoad);
            }
        };

        if (window.addEventListener) {
            window.addEventListener("readystatechange", hReadystatechange, false);
            window.addEventListener("DOMContentLoaded", hDOMContentLoaded, false);
            window.addEventListener("load", hLoad, false);
        }
        else if ((<any>window).attachEvent) {
            (<any>window).attachEvent("onload", hLoad);
        }
    }
}

export function getPureUrl(url: string): string {
    if (!url) return "";

    const pureUrl = url.split('?')[0];
    return pureUrl.split('#')[0];
}

export function lastN<T, TId>(limit: number, array: T[] = [], predicate: (value: T) => TId) {
    if (array.length > limit) array.splice(0, array.length - limit);
    const
        fluent = {
            add: (value: T) => {
                let index = array.findIndex(i => predicate(i) == predicate(value));
                if (index != -1)
                    array.splice(index, 1);

                array.splice(0, 0, value);
                if (array.length > limit)
                    array.splice(array.length - 1, array.length - limit);

                return fluent;
            },
            getAll: () => array,
            get: (id: TId): T | undefined => array.find(i => predicate(i) == id)
        };
    return fluent;
}

export function isPrimitive(test: any): boolean {
    return test !== Object(test);
}
