import { HandlingState } from '../../SmartSignalsProfileDebugger/resources/models/HandlingState';
import { FinalIssuedAction } from '../../api/client/clientApi';
import { IEngine } from '../IEngine';

export interface IOpenedActionBase {
    timeoutId: any;
    resizeObserver?: ResizeObserver;
    removeObserver?: MutationObserver;
    action: FinalIssuedAction;
    stopElementDetection?: () => void;
    actionOpenedOnOrigin: string;
    actionOpenedOnPath: string;
}


export abstract class ActionHandler {
    protected engine: IEngine;

    constructor(engine: IEngine) {
        this.engine = engine;
    }

    public handle(issuedObject: SmartSignals.Models.Issued) {

        if (issuedObject.conf && issuedObject.conf.delayInSeconds && issuedObject.conf.delayInSeconds > 0) {
            setTimeout(() => {
                this.handleAction(issuedObject);
            }, issuedObject.conf.delayInSeconds * 1000);
        }
        else {
            this.handleAction(issuedObject);
        }
    }

    protected abstract handleAction(issuedObject: SmartSignals.Models.Issued): void;


    protected handligStateChange(id?: string, state?: HandlingState) {
        this.engine.sendHandlingState({ logId: id, state: state });
    }

    private isElementInViewport = (el: Element) => {
        var rect = el.getBoundingClientRect();
        return (rect.height || rect.width)
            && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
            && rect.bottom >= 0
            && rect.right >= 0;
    }

    private isElementOverlapping = (el: Element, actionRect: DOMRect | undefined) => {
        if (!actionRect)
            return true;

        var elementRect = el.getBoundingClientRect();

        const actionVerticalReach = actionRect.top + actionRect.height;
        const actionHorizontalReach = actionRect.left + actionRect.width;
        const elementVerticalReach = elementRect.top + elementRect.height;
        const elementHorizontalReach = elementRect.left + elementRect.width;

        return !(
            actionVerticalReach < elementRect.top ||
            actionRect.top > elementVerticalReach ||
            actionHorizontalReach < elementRect.left ||
            actionRect.left > elementHorizontalReach
        );
    }

    protected canShow(action: FinalIssuedAction, isModal: boolean, rootRect: DOMRect | undefined): boolean {
        //if (!action.actionId)
        //    return false;

        const querySelectors = action.pageElementDetection?.querySelectors;
        if (!querySelectors)
            return true;

        let elements: Element[] = [];

        for (let selector of querySelectors) {
            document.querySelectorAll(selector).forEach(x => elements.push(x));
        }

        return isModal
            ? !elements.some(e => this.isElementInViewport(e))
            : !elements.some(e => this.isElementOverlapping(e, rootRect));
    }

    protected startThrottling(openedAction: IOpenedActionBase, isModal: boolean, rootRect: DOMRect | undefined, showCallback: (() => void) | undefined, hideCallback: (() => void) | undefined) {
        let throttlingMs = openedAction.action.pageElementDetection!.throttling;

        const callback = () => {
            const canShow = this.canShow(openedAction.action, isModal, rootRect);
            if (isModal) {
                if (canShow) {
                    showCallback?.();
                    openedAction.stopElementDetection?.();
                    openedAction.stopElementDetection = undefined
                }
                return;
            }

            if (canShow) {
                showCallback?.();
            }
            else {
                hideCallback?.();
            }
        }

        clearTimeout(openedAction.timeoutId);
        openedAction.timeoutId = setTimeout(callback, throttlingMs)
    }
}
