import { ActionHandler } from './ActionHandler';
import { IEngine } from '../IEngine';
import { insertHTMLAndExecute, whenBodyReady } from '../../lib/helpers';
import { ILogger } from '../../lib/logger';
import { HandlingState } from '../../SmartSignalsProfileDebugger/resources/models/HandlingState';

interface HtmlConfiguration {
    html?: string
}

export class InjectHTMLActionHandler extends ActionHandler {

    constructor(engine: IEngine, private logger: ILogger) {
        super(engine);
    }

    protected handleAction(issuedObject: SmartSignals.Models.Issued) {
        if (issuedObject.isInControlGroup) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            return;
        }
        let conf = <HtmlConfiguration>issuedObject.conf;
        whenBodyReady(() => {
            if (!conf || !conf.html) {
                super.handligStateChange(issuedObject.id, HandlingState.Rejected);
                return;
            }

            super.handligStateChange(issuedObject.id, HandlingState.Executed);

            let e = document.createElement('div');
            document.body.appendChild(e);
            insertHTMLAndExecute(e, conf.html);
        });
    }
}
