import { ActionHandler } from './ActionHandler';
import * as jscookie from 'js-cookie';
import { IEngine } from '../IEngine';
import { findFriendlyDomain, removeFromAllDomains, setCookie } from '../../lib/cookieHelpers';
import { ILogger } from '../../lib/logger';
import { HandlingState } from '../../SmartSignalsProfileDebugger/resources/models/HandlingState';

interface CookieConfiguration {
    cookieName?: string,
    cookieValue?: string,
    expireDays?: number
}

export class CookieActionHandler extends ActionHandler {

    constructor(engine: IEngine, private logger: ILogger,
        private ignoreControlGroupValue: boolean = false) {
        super(engine);
    }

    protected handleAction(issuedObject: SmartSignals.Models.Issued) {
        if (issuedObject.isInControlGroup && !this.ignoreControlGroupValue) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            return;
        }
        let conf = <CookieConfiguration>issuedObject.conf;
        if (conf && conf.cookieName && conf.cookieValue) {
            super.handligStateChange(issuedObject.id, HandlingState.Executed);
            this.setCookie(conf.cookieName, conf.cookieValue, conf.expireDays);
        }
    }

    private setCookie(cookieName: string, cookieValue: string, expireDays?: number) {
        let cookieAttrs: jscookie.CookieAttributes;
        removeFromAllDomains(cookieName);
        cookieAttrs = {
            domain: findFriendlyDomain().name,
            expires: expireDays,
            path: "/",
            sameSite: "strict"
        }

        setCookie(cookieName, cookieValue, cookieAttrs);
    }
}
