import { ActionHandler } from './ActionHandler';
import * as overlayActionHandler from './OverlayActionHandler';
import { FinalIssuedAction } from '../../api/client/clientApi';
import { IEngine } from '../IEngine';
import { getPureUrl } from '../../lib/helpers';
import { ILogger } from '../../lib/logger';
import { CollectionActionGroupsProvider } from './CollectionActionGroupsProvider/CollectionActionGroupsProvider';
import { HandlingState } from '../../SmartSignalsProfileDebugger/resources/models/HandlingState';

export class OverlayActionHandlerProxy extends ActionHandler {
    handler: overlayActionHandler.OverlayActionHandler | undefined;
    handlerPromise: Promise<overlayActionHandler.OverlayActionHandler> | undefined;

    constructor(engine: IEngine,
        private logger: ILogger, private _collectionActionGroupsProvider: CollectionActionGroupsProvider) {
        super(engine);
    }

    private static isBrowserSupported() {
        try {
            var div = document.createElement("div");
            div.innerHTML = "<!--[if lt IE 8]><i></i><![endif]-->";
            var isIeLessThan8 = (div.getElementsByTagName("i").length == 1);
            return !isIeLessThan8;
        }
        catch (ignore) { }
        return false;
    }

    protected handleAction(issuedObject: SmartSignals.Models.Issued) {
        if (!OverlayActionHandlerProxy.isBrowserSupported()) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            this.engine.unissuedAction(<FinalIssuedAction>issuedObject);
            return;
        }

        let action = <FinalIssuedAction>issuedObject;
        if (action.triggerUrl && getPureUrl(action.triggerUrl) !== getPureUrl(window.location.href)) {
            super.handligStateChange(issuedObject.id, HandlingState.Rejected);
            this.engine.unissuedAction(action);
            return;
        }

        if (this.handler) {
            this.handler.handleAction(issuedObject);
            return;
        } else if (this.handlerPromise) {
            this.handlerPromise.then(handler => {
                handler.handleAction(issuedObject);
            });
            return;
        }
        this.handlerPromise = import(/* webpackChunkName: "overlay" */ './OverlayActionHandler').then(handler => {
            this.handler = new handler.OverlayActionHandler(this.engine, this.logger, this._collectionActionGroupsProvider);

            this.handler.handleAction(issuedObject);
            return this.handler;
        });
    }

    public showActions(hideGroup?: string): void {
        const handler = this.handler as overlayActionHandler.OverlayActionHandler;
        if (handler) {
            handler.showActions(hideGroup);
        }
    }

    public hideActions(hideGroup?: string): void {
        const handler = this.handler as overlayActionHandler.OverlayActionHandler;
        if (handler) {
            handler.hideActions(hideGroup);
        }
    }
}
