import { ActionHandler } from './ActionHandler';
import { IEngine } from '../IEngine';
import { whenBodyReady } from '../../lib/helpers';
import { ILogger } from '../../lib/logger';

interface PixelConfiguration {
    url?: string,
    alt?: string
}
export class PixelActionHandler extends ActionHandler {

    constructor(engine: IEngine, private logger: ILogger,
        private ignoreControlGroupValue: boolean = false) {
        super(engine);
    }

    protected handleAction(issuedObject: SmartSignals.Models.Issued) {
        if (issuedObject.isInControlGroup && !this.ignoreControlGroupValue)
            return;
        const conf = <PixelConfiguration>issuedObject.conf;
        if (conf && conf.url) {
            this.setPixel(conf.url, conf.alt);
        }
    }

    private setPixel(url: string, alt?: string) {
        whenBodyReady(() => {
            let img = document.createElement('img');
            img.src = url;
            if (alt)
                img.alt = alt;
            img.height = 0;
            img.width = 0;
            img.style.display = 'none'
            document.body.appendChild(img);
        });
    }
}
