import { Engine } from '../engine';
import * as EventSource from './EventSource';

export class URLSourceChange extends EventSource.EventSource {

    private pollingTimer: number | undefined;
    constructor(engine: Engine) {
        super(engine);
    }
    private currentHash = location.hash;
    private currentURL = location.pathname + "?" + location.search;
    private timeID: number | undefined;
    private pushStateOrig?: typeof history.pushState;
    private replaceStateOrig?: typeof history.replaceState;
    private historyApiHook: boolean = false;


    protected doRegister() {
        let that = this;
        if (!('onhashchange' in (<any>window))) {
            (() => {
                let oldHref = location.href;

                this.pollingTimer = window.setInterval(() => {
                    let newHref = location.href;
                    if (oldHref !== newHref) {
                        let _oldHref = oldHref;
                        oldHref = newHref;

                        this.hashChange.call(window);
                    }
                }, 100);
            })();
        }
        else if (window.addEventListener)
            window.addEventListener("hashchange", this.hashChange, false);
        else if ((<any>window).attachEvent)
            (<any>window).attachEvent("onhashchange", this.hashChange);
        if (window.addEventListener)
            window.addEventListener("popstate", this.popState, false);
        else if ((<any>window).attachEvent)
            (<any>window).attachEvent("onpopstate", this.popState);
        if (window.history && !!window.history.pushState && !!window.history.replaceState) {
            this.pushStateOrig = history.pushState;
            history.pushState = function (this: any, ...args) {
                var res = that.pushStateOrig!.apply(this, args);
                try {
                    if (that.currentURL === location.pathname + "?" + location.search && that.currentHash === location.hash)
                        return;
                    //that.engine.track(EventTypes.HistoryPushState);
                    that.engine.push({ event: "General.URLChange" });

                    that.currentURL = location.pathname + "?" + location.search;
                    that.currentHash = location.hash;
                }
                catch (e) { }

                return res;
            }

            this.replaceStateOrig = history.replaceState;
            history.replaceState = function (this: any, ...args) {
                var res = that.replaceStateOrig!.apply(this, args);
                try {
                    if (that.currentURL === location.pathname + "?" + location.search && that.currentHash === location.hash)
                        return;
                    //that.engine.track(EventTypes.HistoryReplaceState);
                    that.engine.push({ event: "General.URLChange" });

                    that.currentURL = location.pathname + "?" + location.search;
                    that.currentHash = location.hash;
                }
                catch (e) { }

                return res;
            }
            this.historyApiHook = true;
        }
        return true;
    }

    protected doUnregister() {
        if (!('onhashchange' in window)) {
            if (this.pollingTimer)
                clearInterval(this.pollingTimer);
            this.pollingTimer = undefined;
        } else if (window.removeEventListener)
            window.removeEventListener("hashchange", this.hashChange, false);
        else if ((<any>window).attachEvent)
            (<any>window).detachEvent("onhashchange", this.hashChange);

        if (window.removeEventListener)
            window.removeEventListener("popstate", this.popState, false);
        else if ((<any>window).attachEvent)
            (<any>window).detachEvent("onpopstate", this.popState);
        if (this.historyApiHook) {
            history.pushState = this.pushStateOrig!;
            history.replaceState = this.replaceStateOrig!;
        }
    }


    private hashChange = () => {
        if (this.currentHash === location.hash)
            return;
        //this.engine.track(EventTypes.HashChange);
        this.engine.push({ event: "General.URLChange" });
        if (this.timeID) {
            clearTimeout(this.timeID);
            this.timeID = undefined;
        }
        this.currentURL = location.pathname + "?" + location.search;;
        this.currentHash = location.hash;

    };

    private popState = (ev: PopStateEvent) => {
        if (this.currentURL === location.pathname + "?" + location.search && this.currentHash === location.hash)
            return;
        this.timeID = window.setTimeout(() => {
            //this.engine.track(EventTypes.HistoryPushState);
            this.engine.push({ event: "General.URLChange" });
            this.currentURL = location.pathname + "?" + location.search;
            this.currentHash = location.hash;
        }, 0);
    };
}
