import { Engine } from '../engine';
import * as EventSource from './EventSource';

export class PageViewSource extends EventSource.EventSource {
    constructor(engine: Engine) {
        super(engine);
    }

    protected doRegister() {
        setTimeout(() => {
            //this.engine.track(EventTypes.PageView);
            this.engine.push({ event: "General.Load" });
        }, 0);
        return true;
    }

    protected doUnregister() {
    }
}
