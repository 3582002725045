import { XdmProvider } from "../../lib/xdmProvider";

export function isDealer(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        let client = new XdmProvider("SmartSignals2-cdn", "https://cdn.smart-digital-solutions.com/SmaSi2-Live/xdm/XdmProviderClient-cdn.html");
        client.getCookie("s2_dlrp").then(value => {
            client.destroy();
            resolve(value == "1");
        }).catch(reason => reject(reason));
    });
    return promise;
}

