import { UserId, UserIdProviderType, ClientApi, UserCookieModel } from '../../api/client/clientApi';
import { RequestType } from '../../SmartSignalsProfileDebugger/types/TrackingRequestResponseData';
import { IEngine } from '../IEngine';
import { IUserIdProvider } from './IUserIdProvider';

export class ServerSideUserIdProvider implements IUserIdProvider {
    private name: string;
    private clientApi: ClientApi;

    private userId?: string;
    private cookieName: string;
    private lifeTime?: number;
    private engine: IEngine;

    public constructor(engineUrl: string, cookieName: string, name: string, engine: IEngine) {
        this.clientApi = new ClientApi(engineUrl, true);
        this.cookieName = cookieName;
        this.name = name;
        this.engine = engine;
    }

    public getType(): UserIdProviderType {
        return UserIdProviderType.LocalServerSide;
    }

    public getUserId(): Promise<UserId> {
        if (this.userId) {
            return Promise.resolve({ id: this.userId, name: this.name, type: this.getType() })
        }
        return this.engine.trackApiCall(RequestType.Identity, { cookieName: this.cookieName }, this.clientApi.userCookie_Get_3(this.cookieName, false))
            .then(data => {
                this.userId = data.value;
                this.lifeTime = data.lifeTime;
                return { id: this.userId, name: this.name, type: this.getType() };
            });
    }

    public getCrossDomainCookie(): Promise<UserCookieModel> {
        return this.engine.trackApiCall(RequestType.Identity, { cookieName: this.cookieName }, this.clientApi.userCookie_Get_3(this.cookieName, true));
    }

    public setUserId(userId: string, lifeTime?: number): Promise<void> {
        if (this.userId === userId && this.lifeTime === lifeTime) {
            return Promise.resolve();
        }
        let model = { value: userId, lifeTime: lifeTime };
        return this.engine.trackApiCall(RequestType.Identity, { cookieName: this.cookieName, model: model }, this.clientApi.userCookie_Put_3(this.cookieName, model))
            .then(() => {
                this.userId = userId;
                this.lifeTime = lifeTime;
            });
    }
}
