/// <reference path="../types/fetch.d.ts" />

import { fetch as fetchPolyfill } from 'whatwg-fetch'

let httpFetch: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };

if (!!window.fetch)
    httpFetch = window;
else
    httpFetch = {
        fetch: fetchPolyfill
    };

export { httpFetch };
