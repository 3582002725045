import * as jscookie from 'js-cookie';
import { UserIdProviderType, UserId, UserSampling, ClientSampling } from '../../api/client/clientApi';
import { IUserIdProvider } from './IUserIdProvider';
import { createTempUserId } from '../../lib/helpers';
import { findFriendlyDomain, FriendlyDomain, getCookieDomainByLevel, setCookie } from '../../lib/cookieHelpers';
import { IProfileDebuggerData, ISamplinData } from '../IEngine';

interface IUserData {
    //dealer flag
    d: boolean;
    //xdm check timestamp
    xts: number;
    lt?: number;

    s?: ISamplinData;

    pd?: IProfileDebuggerData;
}

export class LocalUserIdProvider implements IUserIdProvider {
    private name: string;
    private token: string;
    private domain!: FriendlyDomain;
    private userId: string | undefined;
    private data: IUserData;

    public constructor(name: string, token: string, extendlifeTime: boolean) {
        this.name = name;
        this.token = token;
        this.data = { d: false, xts: 1 };
        this.processCookieValues();

        if (extendlifeTime) {

            this.persist();

            setInterval(() => {
                this.persist();
            }, 10 * 60 * 1000);
        }
    }

    public getType(): UserIdProviderType {
        return UserIdProviderType.Local;
    }

    private getName(): string {
        return this.name;
    }

    public getUserId(): Promise<UserId> {
        if (this.userId)
            return Promise.resolve({ id: this.userId, name: this.getName(), type: this.getType() });

        this.userId = createTempUserId();

        return this.setUserId(this.userId, 30).then(() => {
            return { id: this.userId, name: this.getName(), type: this.getType() };
        });
    }

    public setUserId(userId: string, lifeTime?: number): Promise<void> {
        if (this.update(userId, lifeTime))
            return Promise.resolve();
        else
            return Promise.reject(undefined);
    }

    public get isDealer(): boolean {
        return this.data.d;
    }

    public set isDealer(value: boolean) {
        this.data.d = value;
        this.persist();
    }

    public setSampling(sampling?: UserSampling) {
        if (!sampling)
            return;

        this.data.s = {
            s: sampling.selected,
            v: sampling.ratioVersion,
            ts: new Date().valueOf()
        };

        return this.persist();
    }

    public getSamplingData(): ISamplinData | undefined {
        return this.data.s;
    }

    public getClientSampling(): ClientSampling | undefined {
        const sd = this.data.s;
        if (!sd)
            return undefined;

        return {
            selected: sd.s,
            ratioVersion: sd.v
        };
    }

    public get lastXdmCheck(): number {
        return this.data.xts;
    }

    public set lastXdmCheck(value: number) {
        this.data.xts = value;
        this.persist();
    }

    public isSupported(): boolean {
        return this.domain.isSupported;
    }

    private stringify(): string {
        return (this.userId || '') + '|' + this.domain.level + '|' + btoa(JSON.stringify(this.data));
    }

    private update(userId?: string, lifeTime?: number): boolean {
        if (!userId)
            return false;

        this.userId = userId;
        this.data.lt = lifeTime;

        return this.persist();
    }

    public persist(): boolean {
        if (!this.isSupported())
            return false;
        try {

            let options: jscookie.CookieAttributes = {
                domain: this.domain.name,
                path: '/',
                expires: this.data.lt || 365,
                sameSite: "strict"
            };

            setCookie(this.token, this.stringify(), options);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    public readCookie() {
        let value = jscookie.get(this.token);
        return value;
    }

    private processCookieValues(): void {
        let value = jscookie.get(this.token);
        if (!value) {
            this.domain = findFriendlyDomain();
            return;
        }
        let tokens = value.split('|');
        if (tokens.length < 1) {
            this.domain = findFriendlyDomain();
            return;
        }
        this.userId = tokens[0];

        if (tokens.length > 1) {
            try {
                let level = parseInt(tokens[1]);
                this.domain = {
                    isSupported: true,
                    level: level,
                    name: getCookieDomainByLevel(level)
                };
            }
            catch {
                this.domain = findFriendlyDomain();
            }
        }
        else {
            this.domain = findFriendlyDomain();
            //this.update();
        }
        if (tokens.length > 2) {
            try {
                this.data = JSON.parse(atob(tokens[2]));
            } catch {

            }
        }
    }

    //    public setProfileDebuggerState(debuggerState: number, height?: string) {

    //        this.data.pd = {
    //            ht: height || this.data.pd?.ht || '37vh',
    //            s: debuggerState
    //        };

    //        return this.persist();
    //    }

    //    public getProfileDebuggerConfig(): IProfileDebuggerData {
    //        return this.data.pd || {
    //            s: 0,
    //            ht: '37vh'
    //        };
    //    }
}
