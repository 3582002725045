import * as jscookie from 'js-cookie';
import { getDomainByLevel, getDomainLevel, getSubDomains, isSafari } from './helpers'

export function setCookie(testKey: string, value: string, options: jscookie.CookieAttributes): void {
    if (isSafari() && options.sameSite?.toLowerCase() === "strict")
        delete options.sameSite;

    jscookie.set(testKey, value, options);
}

export function removeCookie(name: string, options: jscookie.CookieAttributes): void {
    if (isSafari() && options.sameSite?.toLowerCase() === "strict")
        delete options.sameSite;

    jscookie.remove(name, options);
}

export function removeFromAllDomains(name: string, path: string = '/'): void {
    let domains = getSubDomains();

    if (jscookie.get(name) != undefined) {
        let options: jscookie.CookieAttributes = {
            domain: '',
            path: path,
            sameSite: "strict"
        };
        removeCookie(name, options);
    }
    let i = domains.length - 1;
    while (--i >= 0 && jscookie.get(name) != undefined)
        removeCookie(name, { domain: domains[i], path: path, sameSite: "strict" });
}

export function canSet(domain: string, testKey: string = 'smartSignalsTest'): boolean {
    if (!navigator.cookieEnabled)
        return false;

    let value = new Date().valueOf().toString();
    try {
        try {
            let expires = new Date();
            expires.setSeconds(expires.getSeconds() + 5);
            setCookie(testKey, value, {
                domain: domain,
                expires: expires,
                sameSite: "strict"
            });

            return jscookie.get(testKey) === value;
        }
        finally {
            removeCookie(testKey, { domain: domain, sameSite: "strict" });
        }
    }
    catch (ignore) { }
    return false;
}


let _cookiesEnabled: boolean;
let _friendlyDomain: FriendlyDomain;

export function cookiesEnabled(testKey: string = 'smartSignalsTest'): boolean {
    if (_cookiesEnabled !== undefined)
        return _cookiesEnabled;

    if (_friendlyDomain && _friendlyDomain.isSupported)
        return _cookiesEnabled = true;

    return _cookiesEnabled = canSet('', testKey);
}

export type FriendlyDomain = {
    isSupported: boolean;
    level: number;
    name: string | undefined;
}

export function findFriendlyDomain(testKey: string = 'smartSignalsTest'): FriendlyDomain {
    if (_friendlyDomain)
        return _friendlyDomain;

    if (_cookiesEnabled === false)
        return _friendlyDomain = { isSupported: false, level: -1, name: undefined };

    let domains = getSubDomains();

    for (let i = 0; i < domains.length; i++) {
        if (canSet(domains[i], testKey)) {
            return _friendlyDomain = {
                isSupported: true,
                name: domains[i],
                level: i
            }
        }
    }
    return _friendlyDomain = { isSupported: false, level: -1, name: undefined };
}

export function findFriendlyDomainLevel(testKey: string = 'smartSignalsTest'): number {
    let cfd = findFriendlyDomain(testKey);
    return cfd.level;
}

export function getCookieDomainByLevel(level: number, domain?: string): string {
    let ddl: number;
    if (level < 0) {
        let cdl = getDomainLevel(domain);
        ddl = Math.max(cdl + level, 0);
    }
    else
        ddl = level;
    return getDomainByLevel(ddl, domain)!;
}
