import * as jschannel from "@greenova/jschannel";
import { whenBodyReady } from './helpers';

export class XdmProvider {

    private jsChannel?: Promise<jschannel.Channel>;
    private scopeName: string;
    private iframe: HTMLIFrameElement | undefined;
    private iframeSrc: string;

    constructor(scopeName: string, iframeSrc: string) {
        this.iframeSrc = iframeSrc;
        this.scopeName = scopeName;

        this.jsChannel = this.createChannel();
    }

    public destroy(): void {
        if (this.jsChannel)
            this.jsChannel.then(channel => {
                channel.destroy();

                if (this.iframe)
                    document.body.removeChild(this.iframe);

                delete this.jsChannel;
                delete this.iframe;
            });                
    }

    private createChannel(): Promise<jschannel.Channel> {
        return new Promise<jschannel.Channel>((resolve, reject) => {
            whenBodyReady(() => {
                this.iframe = document.createElement('iframe');
                this.iframe.src = this.iframeSrc;
                this.iframe.hidden = true;
                this.iframe.style.display = "none";
                this.iframe.style.width = "0";
                this.iframe.style.height = "0";
                this.iframe.style.border = "0";

                document.body.appendChild(this.iframe);

                var channConfig: jschannel.ChannelConfig = {
                    window: this.iframe.contentWindow!,
                    origin: "*",
                    scope: this.scopeName,
                    reconnect: true
                }

                let jsChannel = jschannel.build(channConfig);
                resolve(jsChannel);
            });
        });
    }

    public getOrSetCookie(cookieName: string, value: string | undefined, expires?: number): Promise<string | undefined> {
        return this.jsChannel!.then(jsChannel => {
            return new Promise<string | undefined>((resolve, reject) => {
                jsChannel.call({
                    method: "getOrSetCookie",
                    params: { cookieName: cookieName, value: value, expires: expires },
                    success: (s: string | undefined) => { resolve(s); },
                    error: (error: string) => { reject(error); }
                });
            });
        });
    }

    public setCookie(cookieName: string, value: string, expires?: number): Promise<void> {
        return this.jsChannel!.then(jsChannel => {
            return new Promise<void>((resolve, reject) => {
                jsChannel.call({
                    method: "setCookie",
                    params: { cookieName: cookieName, value: value, expires: expires },
                    success: () => { resolve() },
                    error: (error: string) => { reject(error); }
                });
            });
        });
    }

    public getCookie(cookieName: string): Promise<string> {
        return this.jsChannel!.then(jsChannel => {
            return new Promise<string>((resolve, reject) => {
                jsChannel.call({
                    method: "getCookie",
                    params: { cookieName: cookieName },
                    success: (s: string) => { return resolve(s); },
                    error: (error: string) => { return reject(error); }
                });
            });
        });
    }

    public RemoveCookie(cookieName: string): Promise<void> {
        return this.jsChannel!.then(jsChannel => {
            return new Promise<void>((resolve, reject) => {
                jsChannel.call({
                    method: "removeCookie",
                    params: { cookieName: cookieName },
                    success: () => { resolve(); },
                    error: (error: string) => { return reject(error); }
                });
            });
        });
    }
}
