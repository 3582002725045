import { tryDelete } from "../../lib/helpers";

export class EventSourceManager {
    private eventSources: ({ [name: string]: IEventSource }) = {};

    public register(name: string, eventSource: IEventSource) {
        if (!eventSource)
            return;

        this.eventSources[name] = eventSource;
        eventSource.register();
    }

    public get(name: string): IEventSource {
        return this.eventSources[name];
    }

    public unregister(name: string): void {
        let eventSource = this.eventSources[name];
        if (!eventSource)
            return;

        eventSource.unregister();
        tryDelete(this.eventSources, name);
    }
}

