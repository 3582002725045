import { ActionHandler } from './ActionHandler';
import { DataExchangeType } from '../../api/client/clientApi';
import { tryDelete } from '../../lib/helpers';

export class HandoverHandlerManager {
    private actionHandlers: ({ [actionType: number]: ActionHandler }) = {};

    public register(actionType: DataExchangeType, actionHandler: ActionHandler) {
        if (!actionHandler)
            return;

        this.actionHandlers[actionType] = actionHandler;
    }

    public get(actionType: DataExchangeType): ActionHandler {
        return this.actionHandlers[actionType];
    }

    public unregister(actionType: DataExchangeType): void {
        let actionHandler = this.actionHandlers[actionType];
        if (!actionHandler)
            return;

        tryDelete(this.actionHandlers, actionType);
    }
}
