import { ActionHandler } from './ActionHandler';
import { tryDelete } from '../../lib/helpers';
import { ActionType } from '../../api/client/clientApi';

export class ActionHandlerManager {
    private actionHandlers: ({ [actionType: number]: ActionHandler }) = {};

    public register(actionType: ActionType, actionHandler: ActionHandler) {
        if (!actionHandler)
            return;

        this.actionHandlers[actionType] = actionHandler;
    }

    public get(actionType: ActionType): ActionHandler {
        return this.actionHandlers[actionType];
    }

    public unregister(actionType: ActionType): void {
        let actionHandler = this.actionHandlers[actionType];
        if (!actionHandler)
            return;

        tryDelete(this.actionHandlers, actionType);
    }
}
