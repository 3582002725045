export interface ApiRequestResponseData {
    request: RequestData;
    response: ResponseData;
}

export interface RequestData {
    metadata: RequestMetadata,
    data: any;
}

export enum RequestType {
    Rtde = 1,
    OverlayEvent = 2,
    Identity = 3,
    ExternalAction = 4,
}

export interface RequestMetadata extends Metadata {
    type: RequestType;
    url: string;

    headers?: HeadersInit;
    method?: string;
    mode?: RequestMode;
}

export interface Metadata {
    date: Date;
}

export interface ResponseData {
    metadata: ResponseMetadata,
    data?: any;
}

export interface ResponseMetadata extends Metadata {
    ok: boolean;
    status?: number;

    headers?: Headers;
    type?: ResponseType;
}
