import { ClientApi, ErrorInfo, LogModel, LogContext, SeverityLogLevel, LogType } from '../api/client/clientApi';
import { isPrimitive } from './helpers';

export interface LogInfo {
    message?: string;
    data?: any;
    error?: any;
    context?: LogContext;
}

export interface ILogger {
    info(data: LogInfo): void;
    warn(data: LogInfo): void;
    error(data: LogInfo): void;
    write(severity: SeverityLogLevel, data: LogInfo): void;
}

export class Logger implements ILogger {
    private clientApi: ClientApi;
    private getContext: () => LogContext;

    private readonly _external: ILogger;

    public get external(): ILogger {
        return this._external;
    }

    constructor(clientApi: ClientApi, getContext: () => LogContext) {
        this.clientApi = clientApi;
        this.getContext = getContext;

        this._external = new LoggerWrapper(this, LogType.External);
    }

    public info(data: LogInfo) {
        this.writeInternal(SeverityLogLevel.Info, data, LogType.Internal);
    }

    public warn(data: LogInfo) {
        this.writeInternal(SeverityLogLevel.Warn, data, LogType.Internal);
    }

    public error(data: LogInfo) {
        this.writeInternal(SeverityLogLevel.Error, data, LogType.Internal);
    }

    public write(severity: SeverityLogLevel, data: LogInfo) {
        this.writeInternal(severity, data, LogType.Internal);
    }

    public writeInternal(severity: SeverityLogLevel, data: LogInfo, logType: LogType) {
        if (!data)
            return;
        try {
            let errorInfo: ErrorInfo | undefined;
            let error = data.error;

            if (error !== undefined) {
                if (error instanceof Error) {
                    errorInfo = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack
                    }
                }
                else if (typeof error == 'string') {
                    errorInfo = {
                        message: error
                    }
                }
                else if (isPrimitive(error)) {
                    errorInfo = {
                        message: error.toString()
                    }
                }
            }

            let defaultContext = this.getContext();

            let context = {
                ...defaultContext,
                ...data.context
            };

            let logModel: LogModel = {
                context: context,
                logType: logType,
                severityLogLevel: severity,
                message: data.message,
                data: data.data,
                errorInfo: errorInfo
            }

            this.clientApi.log_Post_3(logModel);
        }
        catch (e) { }
    }

    public setListener(): void {
        try {
            window.addEventListener('error', (e) => {
                try {
                    if (e.filename && e.filename.indexOf('smartsignals2', 0) != -1 && e.error) {
                        this.error({
                            message: 'Unhandled exception',
                            error: e.error
                        });
                    }
                }
                catch (e) { }
            });
        }
        catch (e) { }
    }
}


export class LoggerWrapper implements ILogger {
    constructor(private logger: Logger, private logType: LogType) {
    }

    public info(data: LogInfo): void {
        this.logger.writeInternal(SeverityLogLevel.Info, data, this.logType);
    }

    public warn(data: LogInfo): void {
        this.logger.writeInternal(SeverityLogLevel.Warn, data, this.logType);
    }

    public error(data: LogInfo): void {
        this.logger.writeInternal(SeverityLogLevel.Error, data, this.logType);
    }

    public write(severity: SeverityLogLevel, data: LogInfo): void {
        this.logger.writeInternal(severity, data, this.logType);
    }
}
