export class Deferred<T> {
    constructor() { }

    /* A method to resolve the associated Promise with the value passed.
     * If the promise is already settled it does nothing.
     *
     * @param {anything} value : This value is used to resolve the promise
     * If the value is a Promise then the associated promise assumes the state
     * of Promise passed as value.
     */
    resolve!: (value: T | PromiseLike<T>) => void;

    /* A method to reject the assocaited Promise with the value passed.
     * If the promise is already settled it does nothing.
     *
     * @param {anything} reason: The reason for the rejection of the Promise.
     * Generally its an Error object. If however a Promise is passed, then the Promise
     * itself will be the reason for rejection no matter the state of the Promise.
     */
    reject!: (reason?: any) => void;

    /* A newly created Pomise object.
     * Initially in pending state.
     */
    promise = new Promise<T>((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
    });

    //Object.freeze(this);
}

export function delay(milisec: number) {
    return new Promise<void>(resolve => {
        setTimeout(() => { resolve() }, milisec);
    })
}
