import { IUserIdProvider } from "./IUserIdProvider";
import { IUserIdProviderManager } from "./IUserIdProviderManager";

export class UserIdProviderManager implements IUserIdProviderManager {
    private userIdProviders: { [name: string]: IUserIdProvider } = {};

    public register(name: string, userIdProvider: IUserIdProvider): void {
        if (!userIdProvider)
            return;

        this.userIdProviders[name] = userIdProvider;
    }

    public getProviderByName(name: string): IUserIdProvider {
        return this.userIdProviders[name];
    }

    public getProviders(): ({ [name: string]: IUserIdProvider }) {
        return this.userIdProviders;
    }

    public unregister(name: string): void {
        delete this.userIdProviders[name];
    }
}
