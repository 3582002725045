declare function unescape(value: string): string;

export class ScriptParamsProvider {
    public readonly query: string;
    public readonly path: string;
    public readonly origin: string;
    constructor() {
        const currentScript = document.currentScript as HTMLScriptElement;
        if (!currentScript) {
            throw new Error("No current script found.");
        }

        const scriptUrl = new URL(currentScript.src);

        this.origin = scriptUrl.origin;
        this.query = scriptUrl.search.startsWith('?') ? scriptUrl.search.substring(1) : scriptUrl.search;
        this.path = scriptUrl.pathname.substring(0, scriptUrl.pathname.lastIndexOf('/') + 1);
    }

    private parse() {
        let result: any = {};
        let components = this.query.split('&');

        for (let i = 0; i < components.length; i++) {
            let pair = components[i].split('=');
            let name = pair[0],
                value = pair[1];
            try {
                name = decodeURIComponent(pair[0]);
            }
            catch (e) { }

            if (!result[name])
                result[name] = [];
            // decode
            if (!value)
                value = 'true';
            else {
                try {
                    value = decodeURIComponent(value);
                } catch (e) {
                    value = unescape(value);
                }
            }

            // MacIE way
            let values = result[name];
            values[values.length] = value;
        }
        return result;
    }

    public flatten(): { [index: string]: string | undefined; } {
        let queries = this.parse();
        for (let name in queries)
            queries[name] = queries[name][0];
        return queries;
    }
}

//export class ScriptUrlParser {
//    private scriptPath: string;
//    private domains: Array<string>;
//    private processedScripts: Array<Element>;
//    private query = '';

//    public origin = '';
//    public path = '';


//    constructor(scriptPath: string, domains: Array<string>) {
//        this.scriptPath = scriptPath;
//        this.processedScripts = new Array<Element>();
//        this.domains = domains;
//        this.init();
//    }

//    private init(): void {
//        let srcRegex = new RegExp(this.scriptPath.replace('.', '\\.') + '(\\.min)?\\.js(\\?.*)?$');
//        let scripts = document.getElementsByTagName("script");
//        for (let i = 0; i < scripts.length; i++) {
//            let script = scripts[i];
//            var urlParser = this.getUrlParser(script.src);
//            if (script.src && script.src.match(srcRegex) && this.isFromDomain(urlParser, this.domains)) {
//                if (this.processedScripts.indexOf(script) > -1)
//                    continue;
//                this.processedScripts.push(script);
//                let query = script.src.match(/\?([^#]*)(#.*)?/);
//                this.query = !query ? '' : query[1];
//                this.origin = urlParser.protocol + '//' + urlParser.host;

//                if (urlParser.pathname) {
//                    const pathname = ScriptUrlParser.fixIEPathname(urlParser.pathname);
//                    const pi = pathname.lastIndexOf('/');
//                    if (pi != -1) {
//                        this.path = pathname.substring(0, pi + 1);
//                    }
//                }

//                break;
//            }
//        }
//    }

//    private parse() {
//        let result: any = {};
//        let components = this.query.split('&');

//        for (let i = 0; i < components.length; i++) {
//            let pair = components[i].split('=');
//            let name = pair[0],
//                value = pair[1];
//            try {
//                name = decodeURIComponent(pair[0]);
//            }
//            catch (e) { }

//            if (!result[name])
//                result[name] = [];
//            // decode
//            if (!value)
//                value = 'true';
//            else {
//                try {
//                    value = decodeURIComponent(value);
//                } catch (e) {
//                    value = unescape(value);
//                }
//            }

//            // MacIE way
//            let values = result[name];
//            values[values.length] = value;
//        }
//        return result;
//    }

//    public flatten(): { [index: string]: string; } {
//        let queries = this.parse();
//        for (let name in queries)
//            queries[name] = queries[name][0];
//        return queries;
//    }

//    private isFromDomain(urlParser: HTMLAnchorElement, domains: Array<string>): boolean {
//        for (var i = 0; i < domains.length; i++) {
//            var regexPattern = '^(.*\.)?' + domains[i].replace('.', '\.') + '$';
//            if (urlParser.hostname.match(regexPattern))
//                return true;
//        }

//        return false;
//    }

//    private getUrlParser(url: string): HTMLAnchorElement {
//        var urlParser = document.createElement('a');
//        urlParser.href = url;

//        return urlParser;
//    }
//    private static fixIEPathname(pathName: string): string { // workaround for IE pathname quirk, https://stackoverflow.com/questions/956233/javascript-pathname-ie-quirk
//        if (pathName && pathName.length && pathName.charAt(0) != '/') {
//            return '/' + pathName;
//        }
//        return pathName;
//    }

//    private getPort(urlParser: HTMLAnchorElement): string {
//        if ((urlParser.protocol == "https:" && urlParser.port == "443")
//            || (urlParser.protocol == "http:" && urlParser.port == "80"))
//            return '';
//        return ':' + urlParser.port;
//    }
//}
