export class CollectionActionGroupsProvider {

    private _collectionActionGroups: { [id: string]: string | undefined; } = {};

    public isCollectionActionGroupsActive(groups?: string[]): boolean {
        if (groups) {
            for (let group of groups) {
                if (this._collectionActionGroups[group])
                    return true;
            }
        }
        return false;
    }

    public activateCollectionActionGroups(groups?: string[], actionId?: string): boolean {
        if (groups) {
            if (this.isCollectionActionGroupsActive(groups)) {
                return false;
            }

            for (let group of groups) {
                this._collectionActionGroups[group] = actionId;
            }
        }
        return true;
    }

    public clearCollectionActionGroups(groups?: string[]) {
        if (groups) {
            for (let group of groups) {
                delete this._collectionActionGroups[group];
            }
        }
    }

    public clearByActionId(actionId: string) {
        if (actionId) {
            var groups = Object.keys(this._collectionActionGroups).filter(key => this._collectionActionGroups[key] === actionId);
            this.clearCollectionActionGroups(groups);
        }
    }
}
