import { Engine } from '../engine';

export var EventTypes = {
    PageView: "PageView",
    HashChange: "HashChange",
    SetVariable: "SetVariable",
    OffsiteTracking: "OffsiteTracking",
    HistoryPushState: "HistoryPushState",
    HistoryReplaceState: "HistoryReplaceState",
    PageHidden: "PageHidden",
    PageVisible: "PageVisible",
    PageClose: "PageClose",
    XTrack: "XTrack",
};

export class EventSource {
    protected engine: Engine;

    protected isRegistered: boolean = false;

    constructor(engine: Engine) {
        this.engine = engine;
    }

    public register(): boolean {
        if (this.isRegistered)
            throw new Error('Already register');

        this.isRegistered = this.doRegister();
        return this.isRegistered;
    }

    protected doRegister(): boolean {
        throw new Error('abstract');
    }

    public unregister(): void {
        if (!this.isRegistered)
            return;
        this.isRegistered = false;
        this.doUnregister();
    }

    protected doUnregister() {
        throw new Error('abstract');
    }
}
